import Sections from "../components/Sections"
import React from 'react'
import { FormInput, FormSelect } from "../components/Forms/Inputs"
export const AOS_DURATION = "2000"


// export const GRID_ROWS = [
//     "grid-rows-1",
//     "grid-rows-1",
//     "grid-rows-1",
//     "grid-rows-1",
//     "grid-rows-1",
//     "grid-rows-1",
//     "grid-rows-1",
//     "grid-rows-1",
//     "grid-rows-1",
//     "grid-rows-1",
//     "grid-rows-1",
//     "grid-rows-1",
// ]


export const TypesOfSection = {
    Header: (props) => <Sections.Header {...props}  />,
    About: (props) => <Sections.About {...props} />,
    Services: (props) => <Sections.Services {...props} />,
    CaseStudies: (props) => <Sections.CaseStudies {...props} />,
    Testimonials: (props) => <Sections.Testimonials {...props} />,
    Projects: (props) => <Sections.Projects {...props} />,
    Contact: (props) => <Sections.Contact {...props} />,
}

export const TypesOfInput = {
    email: (props) => <FormInput {...props}  />,
    text: (props) => <FormInput {...props}  />,
    textarea: (props) => <FormInput textArea {...props}  />,
    select: (props) => <FormSelect {...props} />,
    submit: (props) => <FormInput {...props} inputProps={{ children: props.text || "Submit", className: "bg-accent-dark text-primary" }} />,
}
