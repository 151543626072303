import React from 'react'
import PropTypes from 'prop-types'
import Section from './Section'
import Img from 'gatsby-image'
import { Box } from '../Elements'
import { AOS_DURATION } from '../../lib/Constants'
import Placeholders from '../../hooks/UsePlaceholders'
import Linked from '../Linked'
import UseContent from '../../hooks/UseContent'
import UseMarkdown from '../../hooks/UseMarkdown'


const ProjectsSection = ({ id, className, title, content }) => {
  return (
    <Section id={id} title={title} className={` ${className}`}>

      <div className={`grid sm:grid-cols-2 gap-8 md:gap-0 `}
      >
        { content && content.map((project, index) => {
          const { header, description, image, linkTo } = UseContent(project, ProjectsSection.defaultProps.content[index])
          return (
          <Linked
            key={`${header} + ${index}`}
            linkTo={linkTo || "/"}>
            <article 
              data-aos="zoom-out"
              data-aos-duration={AOS_DURATION}
            className="bg-secondary md:w-4/5 lg:w-2/3 mx-auto text-primary relative hover:shadow-3xl transition duration-500 hover:cursor-pointer mt-16">
              <Img className="w-full h-40 z-20" fluid={image.fluid || Placeholders().image} ></Img>
              <div className="relative z-10 bg-secondary">
                <summary className="p-4 list-none">
                  <h3 className="text-2xl">{header}</h3>
                  {UseMarkdown(description)}
                </summary>
              </div>
              <Box bottom={index % 2 !== 0} top={index % 2 === 0} alternate={index % 2 !== 0} className="bg-accent z-0" />
            </article>
          </Linked>
        )})}
      </div>
    </Section>
  )
}

ProjectsSection.propTypes = {
  title: PropTypes.string,
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  testimonials: PropTypes.arrayOf(PropTypes.exact({
    header: PropTypes.string,
    description: PropTypes.string,
    linkTo: PropTypes.string,
    image: PropTypes.object
  }))
}

ProjectsSection.defaultProps = {
  title: 'Projects',
  id: "projects",
  content: [
    {
      header: 'My Cool Project',
      description: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Explicabo omnis dolorem repudiandae fugit aspernatur impedit velit placeat harum aut voluptates eos ipsa, quisquam mollitia non consequuntur natus quidem saepe est!",
    },
    {
      header: 'My Support Work',
      description: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Explicabo omnis dolorem repudiandae fugit aspernatur impedit velit placeat harum aut voluptates eos ipsa, quisquam mollitia non consequuntur natus quidem saepe est!",
    },
  ]
}

export default ProjectsSection
