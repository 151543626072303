
import { useStaticQuery, graphql } from 'gatsby'



const Placeholders = () => {
    const data = useStaticQuery(graphql`
    query Placeholder {
        placeholder: file(name: {eq: "placeholder"}) {
            name
            childImageSharp {
                fluid(maxWidth: 640) {
                        src
                        srcSet
                    }   
                }
            }
        placeholderLogo: file(relativePath: { eq: "gatsby-icon.png" }) {
            childImageSharp {
            fixed(height: 160) {
                ...GatsbyImageSharpFixed
                }
            }
        }
        placeholderBg: file(relativePath: { eq: "white-sand.jpg" }) {
            childImageSharp {
            fluid(maxWidth: 1800) {
                ...GatsbyImageSharpFluid
                }
            }
        }
    }
   
    `)
    return {
        image: data.placeholder.childImageSharp.fluid,
        bg: data.placeholderBg.childImageSharp.fluid,
        logo: data.placeholderLogo.childImageSharp.fixed,
    }
}

export default Placeholders
