import React from 'react'
import PropTypes from 'prop-types'
import Section from './Section'
import { Accent } from '../Elements'
import UseContent from '../../hooks/UseContent'
import UseMarkdown from '../../hooks/UseMarkdown'
import Form from '../Forms/Form'


const ContactSection = ({ id, className, title, content }) => {

  const { description, header, inputs, successMessage, formName, submitButtonText } = UseContent(content, ContactSection.defaultProps.content[0])
  return (
    <Section title={title} id={id} className={`${className}`}>

        <div className="flex flex-col md:flex-row">
        <div className="flex flex-col md:flex-row justify-center order-2 w-full lg:w-1/2 mx-auto">
        <Form inputs={inputs} successMessage={successMessage} submitButtonText={submitButtonText} formName={formName} />
        </div>

        <div className="flex flex-col justify-center lg:w-1/2 order-1 md:order-2 mb-12 md:mb-0 md:ml-12 mx-auto">
            <div className="p-4 relative">
                <h2 className="text-3xl">{header}</h2>
                { UseMarkdown(description) }
                <Accent />
            </div>
        </div>
        </div>
     
    </Section>
  )
}

ContactSection.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  content: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    header: PropTypes.string,
    description: PropTypes.shape({ 
      id: PropTypes.string,
      text: PropTypes.string
    }),
    linkTo: PropTypes.string,
  }))
}

ContactSection.defaultProps = {
  id: "about",
  content : [{
    header: "About",
    description: {
      text: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ducimus fugiat dignissimos qui quas repudiandae sed obcaecati ullam? Esse, sint? Ipsam laudantium suscipit reiciendis! Laborum, adipisci dicta nesciunt libero optio excepturi."
    } 
  }]
}

export default ContactSection
