import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Nav from "./Nav"
import AOS from 'aos'
import 'aos/dist/aos.css'
import { AnchorLink } from "gatsby-plugin-anchor-links";
import Footer from "./Footer"



const Layout = ({ children, homeLink={}, navLinks }) => {
  useEffect(() => {
    AOS.init();
  }, [])


  const { siteMetaData } = useStaticQuery(graphql`
    query {
        siteMetaData: contentfulSiteMetaData {
          instagram
          twitter
          title
          publicUrl
          description {
            text: description
          }
          image {
            sizes(maxWidth: 200, quality: 100) {
              src
            }
          }
          linkedIn
          author  
        }
      }

  `)

    const { instagram, twitter, linkedIn } = siteMetaData

    const socialLinks = [{instagram}, {twitter}, {linkedIn}]

    const mapSocialLinks = socialLinks.map((link,index) => ({
      linkTo: socialLinks[index][`${Object.keys(socialLinks[index])[0]}`],
      icon: `fa-${Object.keys(socialLinks[index])[0].toLowerCase()}`
    }))


  return (
    <>
      <Nav homeLink={homeLink} links={navLinks} socialLinks={mapSocialLinks} homeImage={siteMetaData.image} />
      <main className="bg-primary">{children}</main>
      <div className="w-full text-center text-5xl text-secondary-dark mt-12">
        <AnchorLink to={`/${homeLink ? homeLink.linkTo : ""}`} ><i className="fa fa-chevron-up"></i></AnchorLink>
      </div>
      <Footer links={mapSocialLinks} />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  homeLink: PropTypes.shape({
    name: PropTypes.string,
    linkTo: PropTypes.string
  })
}


Layout.defaultProps = {
  homeLink: {
    name: "Home",
    linkTo: "/"
  }
}

export default Layout
