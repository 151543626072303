import Markdown from 'markdown-to-jsx';
import React from 'react'

const Li = ({ children, ...props }) => <li {...props}>{children}</li>

export default function(text) {
    return <Markdown
    className="text-left"
            options={{
                overrides: {
                    li: {
                        component: Li,
                        props: {
                            className: 'ml-4 text-left list-disc',
                        },
                    },
                },
            }}
    
    >
        { text }

    </Markdown>
}