import React from 'react'
import PropTypes from 'prop-types'
import Section from './Section'
import Img from 'gatsby-image'
import { SideQuote, Testimonial, ImageSVG } from '../Elements'
import { AOS_DURATION } from '../../lib/Constants'
import Placeholders from '../../hooks/UsePlaceholders'
import UseContent from '../../hooks/UseContent'
import UseMarkdown from '../../hooks/UseMarkdown'


const TestimonialsSection = ({ id, className, title, content }) => {
  return (
    <Section id={id} title={title} className={` ${className}`}>

      
        {
          content && content.map((testimonial, index) => {
            const { header, description, image } = UseContent(testimonial, TestimonialsSection.defaultProps.content[index])

            return <div key={`${index}-${header}`} className={`grid grid-cols-3 my-12 lg:grid-cols-5 gap-12`}>
              {index % 2 === 0 ?
                <Testimonial 
                  data-aos={`${index % 2 ? 'fade-left' : 'fade-right'}`}
                  data-aos-duration={AOS_DURATION}
                  span={3} >
                  <SideQuote author={header} alternate={false} >{UseMarkdown(description)}</SideQuote>
                </Testimonial> :
                <Testimonial span={2} className="hidden lg:block" >
                  {
                    image.logo ? <Img className="mx-auto" imgStyle={{ objectFit: "contain" }} fluid={(image && image.logo) || Placeholders().logo} />
                      : image.svg ? <ImageSVG src={image.svg.url} alt={image.title} />:
                        <Img className="mx-auto" imgStyle={{ objectFit: "contain" }} fluid={Placeholders().logo} />
                  }
                </Testimonial>
              }
              {index % 2 !== 0 ?
                <Testimonial  span={3} key={`${header}-${index}`}
                  data-aos={`${index % 2 ? 'fade-left' : 'fade-right'}`}
                  data-aos-duration={AOS_DURATION}
                >
                  <SideQuote author={header} alternate >{UseMarkdown(description)} </SideQuote>
                </Testimonial> :
                <Testimonial key={`${index}-${header}`} span={2} className="hidden lg:block">
                  {
                    image.logo ? <Img className="mx-auto" imgStyle={{ objectFit: "contain" }} fluid={(image && image.logo) || Placeholders().logo} />
                      : image.svg ? <ImageSVG src={image.svg.url} alt={image.title} /> :
                        <Img className="mx-auto" imgStyle={{ objectFit: "contain" }} fluid={Placeholders().logo} />
                  }
                </Testimonial>
              }
            </div>
          })
        }
    </Section>
  )
}

TestimonialsSection.propTypes = {
  title: PropTypes.string,
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  testimonials: PropTypes.arrayOf(PropTypes.exact({
    header: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.object
  }))
}

TestimonialsSection.defaultProps = {
  title: 'Testimonials',
  id: "testimonials",
  content: [
    {
      header: "Company 1",
      description: {
        text: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ducimus fugiat dignissimos qui quas repudiandae sed obcaecati ullam? Esse, sint? Ipsam laudantium suscipit reiciendis! Laborum, adipisci dicta nesciunt libero optio excepturi."
      }
    },
    {
      header: "Company 2",
      description: {
        text: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ducimus fugiat dignissimos qui quas repudiandae sed obcaecati ullam? Esse, sint? Ipsam laudantium suscipit reiciendis! Laborum, adipisci dicta nesciunt libero optio excepturi."
      }
    },
    {
      header: "Company 3",
      description: {
        text: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ducimus fugiat dignissimos qui quas repudiandae sed obcaecati ullam? Esse, sint? Ipsam laudantium suscipit reiciendis! Laborum, adipisci dicta nesciunt libero optio excepturi."
      }
    },

  ]
}

export default TestimonialsSection
