import React from 'react'
import PropTypes from 'prop-types'
import Linked from './Linked'

const SocialLinks = ({ links, className }) => {


    return (
        <ul className="flex mx-auto justify-center my-4 flex wrap w-full">
            {
                links.map((link, index) => (
                    <Linked key={`${link.linkTo}-${index}`} linkTo={link.linkTo}>
                        <li className={`text-4xl mx-4 cursor-pointer hover:text-accent ${className}`}>
                            <i className={`fab ${link.icon} `} /></li>
                    </Linked>
                ))
            }

        </ul>
    )
}

SocialLinks.propTypes = {
    links: PropTypes.arrayOf(PropTypes.exact({
        icon: PropTypes.string,
        linkTo: PropTypes.string
    }))
}


SocialLinks.defaultProps = {
    links: [
        {
            icon: 'fa-instagram',
            linkTo: '/'
        },
        {
            icon: 'fa-twitter',
            linkTo: '/'
        },
        {
            icon: 'fa-linkedin',
            linkTo: '/'
        },
    ],
    className: "text-primary-text"
}

export default SocialLinks
