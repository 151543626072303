import React from 'react'
import PropTypes from 'prop-types'
import Section from './Section'
import UsePlaceholders from '../../hooks/UsePlaceholders'
import Img from 'gatsby-image'
import { Accent } from '../Elements'
import UseContent from '../../hooks/UseContent'
import UseMarkdown from '../../hooks/UseMarkdown'


const AboutSection = ({ id, className, title, content }) => {

  const { description, header, image } = UseContent(content[0], AboutSection.defaultProps.content[0])
  return (
    <Section id={id} className={`${className} pt-24 lg:pt-32`}>
      <div className={`flex flex-col md:flex-row w-full `} style={{ minHeight: 400 }} >
        {image ?
          <div className={`w-4/5 md:w-2/3 lg:w-1/2 flex flex-col mx-auto mb-12 lg:mb-0`} >
            <Img className="h-64 md:h-full w-2/3 md:w-2/3 lg:w-1/2 mx-auto rounded-full" fluid={ (image && image.fluid )|| UsePlaceholders().image} alt={ image ? image.title : "Placeholder"} />
          </div> :
          ""
        }
        <div className={`w-full lg:w-1/2 mx-auto leading-relaxed flex flex-col justify-center`}>
          <h2 className="font-light">{header}</h2>
          {UseMarkdown(description)}
          <Accent />
        </div>
      </div>
    </Section>
  )
}

AboutSection.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  content: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    header: PropTypes.string.isRequired,
    description: PropTypes.exact({ 
      id: PropTypes.string,
      text: PropTypes.string
    }).isRequired,
    image: PropTypes.object
  }))
}

AboutSection.defaultProps = {
  id: "about",
  content : [{
    header: "About",
    description: {
      text: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ducimus fugiat dignissimos qui quas repudiandae sed obcaecati ullam? Esse, sint? Ipsam laudantium suscipit reiciendis! Laborum, adipisci dicta nesciunt libero optio excepturi."
    } 
  }]
}

export default AboutSection
