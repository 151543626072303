export default function(content, defaults) {
    const isArray = Array.isArray(content)
    const fullContent = isArray ? convertArrayOfObjectsToObject(content) : content
    // console.log(fullContent)
    const activeContent = fullContent ? fullContent : defaults
    const { header, image={}, linkTo, inputs, successMessage, formName, submitButtonText } = activeContent 
    const description = (activeContent.description && activeContent.description.text) || ""
    return { header, description, image, linkTo, inputs, successMessage, formName, submitButtonText }
} 




const convertArrayOfObjectsToObject = (array) => {
    const initialValue = {};
    return array.reduce((obj, item) => {

        return {
            ...obj,
            ...item
        };
    }, initialValue);
};