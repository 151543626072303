import React from 'react'
import PropTypes from 'prop-types'
import Section from './Section'
import Img from 'gatsby-image'
import { Accent } from '../Elements'
import Linked from '../Linked'
import { AOS_DURATION } from '../../lib/Constants'
import Placeholders from '../../hooks/UsePlaceholders'
import UseContent from '../../hooks/UseContent'
import UseMarkdown from '../../hooks/UseMarkdown'


const CaseStudiesSection = ({id, className, title, content }) => {
    return (
        <Section id={id} title={title} className={`${className}`}>
        <div className={`grid grid-rows-1`}>
          {
            content && content.map((caseStudy, index) => {
              const { header, description, image, linkTo } = UseContent(caseStudy, CaseStudiesSection.defaultProps.content[index])
              return (
              <Linked key={`${caseStudy.header}-${index}`} linkTo={linkTo || "/"}>
                <article className={`grid grid-cols-1 md:grid-cols-2 grid-rows-1 `}>
                  <div className={`h-56 md:h-full flex ${(index) % 2 === 0 ? 'md:order-1' : 'md:order-2'}`}>
                    <Img className="w-full" imgStyle={{ objectFit: "contain" }} fluid={image.fluid || Placeholders().image} ></Img>
                  </div>
                  <div
                    data-aos={`${index % 2 ? 'fade-right' : 'fade-left'}`}
                    data-aos-duration={AOS_DURATION}
                    className={`bg-black text-gray-100 p-8 ${(index + 1) % 2 === 0 ? 'order-1' : 'order-2'} leading-relaxed`}>
                    <h3 className="font-light my-2" >{header}</h3>
                    { UseMarkdown(description) }
                    <Accent />
                  </div>
                </article>
              </Linked>
            )})
          }
        </div>
      </Section>

    )
}

CaseStudiesSection.propTypes = {
    title: PropTypes.string,
    id: PropTypes.string.isRequired,
    className: PropTypes.string,
    caseStudies: PropTypes.arrayOf(PropTypes.exact({
        header: PropTypes.string,
        description: PropTypes.string,
        linkTo: PropTypes.string,
        img: PropTypes.object
    }))
}

CaseStudiesSection.defaultProps = {
    title: 'Case Studies',
    id:"case-studies",
    content: [
        { 
            header: 'My work with ExampleTech',
            description: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Explicabo omnis dolorem repudiandae fugit aspernatur impedit velit placeat harum aut voluptates eos ipsa, quisquam mollitia non consequuntur natus quidem saepe est!",
            linkTo: '/',
        },
        { 
            header: 'How I changed the Industry',
            description: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Explicabo omnis dolorem repudiandae fugit aspernatur impedit velit placeat harum aut voluptates eos ipsa, quisquam mollitia non consequuntur natus quidem saepe est!",
            linkTo: '/',
        },
    ]
}

export default CaseStudiesSection
