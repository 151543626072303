import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
// import logo from "../images/site/seo-logo.svg"

function SEO({ description, lang, meta, title, image, article, offSiteImage=false, twitterUsername }) {
  const { site, contentfulSiteMetaData, contentfulSiteMetaData : { placeholder } } = useStaticQuery(
    graphql`
      query SEO {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
        contentfulSiteMetaData {
          instagram
          twitter
          title
          publicUrl
          description {
            text: description
          }
          linkedIn
          author
          placeholder: image {
            sizes(maxWidth: 200, quality: 90) {
              src
            }
          }
        }
      }

    `
  )

  const metaDescription = description || contentfulSiteMetaData.description.text || site.siteMetadata.description
  const metaImage = !offSiteImage && image ? `${site.siteMetadata.siteUrl}${image}` : offSiteImage && image ? image : placeholder && placeholder.sizes.src
  const metaTitle = title ? `${title} | ${site.siteMetadata.title}` : site.siteMetadata.title
  const twitterAccount = twitterUsername ? `${twitterUsername}`: 
        contentfulSiteMetaData.twitter ? `@${contentfulSiteMetaData.twitter.split(/(?:\.com\/)([\w-]+)/g)[1]}` : ""


  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
    >
      <noscript>Your browser does not support JavaScript! A London List works best with javascript ( and by best only ). </noscript>

      <meta name="description" content={metaDescription} />
      { metaImage && <meta name="image" content={metaImage} /> }
      

      {/* Facebook */}
      <meta property="og:url" content={site.siteMetadata.siteUrl} />
      <meta property="og:type" content={article ? `article` : `website`} />
      <meta property="og:title" content={metaTitle} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:image" content={metaImage} />

      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:url" content={site.siteMetadata.siteUrl} />
      <meta name="twitter:title" content={metaTitle} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="twitter:image" content={metaImage} />

      {twitterAccount && (
        <meta name="twitter:creator" content={twitterAccount} />
      )}

      <meta charSet="UTF-8" />
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
