import React from 'react'
import PropTypes from 'prop-types'
import UsePlaceholders from '../../hooks/UsePlaceholders'
import Img from 'gatsby-image'
import { Box, Accent } from '../Elements'
import { AOS_DURATION } from '../../lib/Constants'
import Section from './Section'
import UseContent from '../../hooks/UseContent'
import UseMarkdown from '../../hooks/UseMarkdown'


const ServicesSection = ({ id, title, className, content, ...restProps }) => {

  return (<Section id={id} title={title} className={` ${className}`} {...restProps} >
    <div className={`grid grid-rows-1 row-gap-16`}>
      {
        content && content.map((service, index) => {
          const { image, description, header } = UseContent(service, ServicesSection.defaultProps.content[index])
          return <div
            key={`${service.header}-${index}`}
            data-aos={`${index % 2 ? 'fade-up-right' : 'fade-up-left'}`}
            data-aos-duration={AOS_DURATION}
            className={`grid md:grid-cols-7 w-full text-center`}
            style={{ minHeight: 400 }}
          >
            <div className={`col-span-4 ${(index) % 2 ? 'md:order-2' : 'md:order-1'} relative h-40 md:h-full`}>
              <Box alternate={(index + 1) % 2 === 0} top className="bg-indigo-800" />
              <Img className="w-full h-full" fluid={(image && image.fluid) || UsePlaceholders().image} ></Img>
            </div>
            <div className={`p-6 bg-black col-span-3 text-gray-100 ${(index) % 2 ? 'md:order-1' : 'md:order-2'} flex flex-col justify-center`}>
              <h3 className="text-left text-3xl my-2 ">{header}</h3>
                { description && UseMarkdown(description) }
              <Accent />
            </div>

          </div>
        })
      }

    </div>
  </Section>
  )
}

ServicesSection.propTypes = {
  content: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    header: PropTypes.string.isRequired,
    description: PropTypes.shape({ 
      id: PropTypes.string,
      text: PropTypes.string
    }).isRequired,
    image: PropTypes.object
  })),
  title: PropTypes.string,
  id: PropTypes.string.isRequired

}
ServicesSection.defaultProps = {
  content : [{
    header: "Service Offered",
    description: {
      text: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ducimus fugiat dignissimos qui quas repudiandae sed obcaecati ullam? Esse, sint? Ipsam laudantium suscipit reiciendis! Laborum, adipisci dicta nesciunt libero optio excepturi."
    } 
  }, 
  {
    header: "Service Offered",
    description: {
      text: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ducimus fugiat dignissimos qui quas repudiandae sed obcaecati ullam? Esse, sint? Ipsam laudantium suscipit reiciendis! Laborum, adipisci dicta nesciunt libero optio excepturi."
    } 
  }],
  title: "Services",
  id: "services"
}

export default ServicesSection
