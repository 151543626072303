import React from 'react'


export const Accent = ({ className = "border-accent w-1/4 mt-4", }) => <div className={`border-b-4 ${className}`} />


export const Box = ({ alternate, bottom, top, className = "bg-secondary" }) => <div className={`w-32 h-32 absolute ${className}`} style={{ bottom: bottom ? -15 : "", top: top ? -15 : "", left: alternate ? "" : -15, right: alternate ? -15 : 0 }} ></div>


export const SideQuote = ({ children, alternate = false, className = "border-accent", author = "First Last, Company", ...restProps }) => <>
        <blockquote {...restProps} className={`${alternate ? "border-r-4 pr-4" : "border-l-4 pl-4"} mx-4  my-4 ${className}`}>{children}</blockquote>
        <h3 className={`text-${alternate ? "right" : "left"} mx-4 text-xl`}>{author}</h3>
</>

export const CenterQuote = ({ children, className = "border-accent", ...restProps }) => <blockquote {...restProps} className={`text-center mx-4 pl-4 my-4 ${className}`}>"{children}"</blockquote>

export const Testimonial = ({ span = 1, children, className = "bg-secondary", ...restProps }) => {
        const spanClasses = ['col-span-1', 'col-span-2', 'col-span-3', 'col-span-4', 'col-span-5']
        const spanClass = span > 0 && span < 5 ? spanClasses[span - 1] : 1
        return <div {...restProps} className={`w-full text-primary p-4 flex flex-col justify-center  ${spanClass} ${className}`}>
                {children}
        </div>
}

export const ImageSVG = ({className, alt, ...restProps}) => <div className="flex h-full">
         <img className="w-full h-full self-center" alt={alt} {...restProps} /> 
</div>