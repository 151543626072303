
import Section from './Section'
import Header from './HeaderSection'
import Services from './ServicesSection'
import CaseStudies from './CaseStudiesSection'
import About from './AboutSection'
import Testimonials from './TestimonialsSection'
import Projects from './ProjectsSection'
import Contact from './ContactSection'

export default {
    Section,
    Header,
    Services,
    CaseStudies,
    About,
    Testimonials,
    Projects,
    Contact
}