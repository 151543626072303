import React from 'react'
import PropTypes from 'prop-types'
import Section from './Section'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import UsePlaceholders from '../../hooks/UsePlaceholders'


const HeaderSection = ({id, className, img, header, subHeader, nextSectionId}) => {
    return (
        <Section id={id} className={className} img={img || UsePlaceholders().bg} style={{ padding: 0 }}>
            <div className="flex flex-col w-full h-full justify-center md:justify-center max-w-5xl mx-auto px-8">
                <div
                    className="relative self-center mx-auto my-40 md:my-0">
                    <h1
                        data-aos="fade-left"
                        data-aos-duration={"3000"}
                        className="text-center text-3xl md:text-5xl self-center mx-auto tracking-widest font-light text-primary mt-2 w-full relative z-30 px-8 uppercase ">{header}</h1>
                    { 
                        subHeader ? 
                        <p
                            data-aos="fade-left"
                            data-aos-duration={"3000"}
                            className="text-center tracking-wide self-center mx-auto tracking-wide font-thin text-primary w-full relative z-30 mb-4 uppercase px-6 ">{subHeader}
                            </p> :
                        "" 
                        }
                    <div
                        data-aos="fade-left"
                        data-aos-duration={"3000"}
                        className="w-full h-full bg-secondary absolute top-0 self-center transform z-20"></div>
                    <div
                        data-aos="fade-right"
                        data-aos-duration={"3000"}
                        className="w-full h-full bg-accent -mt-4 -ml-4 absolute top-0 self-center transform z-10"></div>
                </div>


                {/* To navigate to the next section */}
                {
                    nextSectionId ? 
                        <aside className="text-center md:hidden">
                            {/* Will need to be the first section in the nav */}
                            <AnchorLink to={`/#${nextSectionId}`} >
                                <i className="fa fa-chevron-down text-4xl text-dark-accent cursor-pointer animated slow pulse infinite"/>
                            </AnchorLink>
                        </aside> : ""
                }
            </div>
        </Section>

    )
}

HeaderSection.propTypes = {
    header: PropTypes.string.isRequired,
    subHeader: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    nextSectionId: PropTypes.string.isRequired,
    
}

HeaderSection.defaultProps = {
    header: 'White Sands',
    subHeader: 'Portfolio for Freelances',
    id:"home",
    nextSectionId: "#services"
}

export default HeaderSection
