import React from 'react'
import PropTypes from 'prop-types'
import SocialLinks from './SocialLinks'
import Linked from './Linked'

const Footer = ({links, className, ...restOfProps}) => {
    return (
        <footer
            {...restOfProps}
            className={`py-24 text-center bg-primary text-secondary-light md:w-1/2 mx-auto flex flex-col justify-center my-12 ${className}`}>
            <SocialLinks links={links}/>
            <small className="hidden lg:block">Designed and built by <Linked className="text-accent" linkTo="https://antler.digital">Antler Digital</Linked></small>
        </footer>
    )
}

Footer.propTypes = {
    links: PropTypes.arrayOf(PropTypes.exact({
        icon: PropTypes.string,
        linkTo: PropTypes.string
    })),
    className: PropTypes.string
}

export default Footer
